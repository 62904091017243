<template>
  <Layout>
    <template v-if="this.$route.name == 'add-email-content'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-4"
                v-if="$route.name == 'edit-email-content'"
              >
                <label style="font-weight: 500">Endpoint ID</label>
                <b-form-select
                  id="wn"
                  v-model="form.endpoint_id"
                  :options="endpoint_dropdown"
                  value-field="key"
                  text-field="label"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label-for="input-3"
                class="col-4"
              >
                <label style="font-weight: 500"
                  >Email Type <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-3"
                  placeholder="Enter Email Type"
                  v-model="form.email_type"
                  :class="{
                    'is-invalid': submitted && $v.form.email_type.$error,
                  }"
                >
                </b-form-input>
                <div
                  v-if="submitted && !$v.form.email_type.required"
                  class="invalid-feedback"
                >
                  Email Type is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label-for="input-2"
                class="col-4"
              >
                <label style="font-weight: 500">From</label>
                <b-form-input
                  id="input-2"
                  placeholder="Enter From"
                  v-model="form.from"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label-for="input-2"
                class="col-4"
              >
                <label style="font-weight: 500">Reply To</label>
                <b-form-input
                  id="input-2"
                  placeholder="Enter Reply To"
                  v-model="form.replyTo"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label-for="input-2"
                class="col-4"
              >
                <label style="font-weight: 500"
                  >Subject <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-2"
                  placeholder="Enter Subject"
                  v-model="form.subject"
                  :class="{
                    'is-invalid': submitted && $v.form.subject.$error,
                  }"
                >
                </b-form-input>
                <div
                  v-if="submitted && !$v.form.subject.required"
                  class="invalid-feedback"
                >
                  Subject is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label-for="input-2"
                class="col-4"
              >
                <label style="font-weight: 500">Placeholders</label>
                <b-form-input
                  id="input-2"
                  placeholder="Enter Placeholders"
                  v-model="form.placeholders"
                ></b-form-input>
              </b-form-group>
              <div class="col-12">
                <div
                  class="d-flex justify-content-start align-items-center mb-2"
                >
                  <label class="mb-0" style="font-weight: 500"
                    >Email HTML Content <span style="color: red">*</span></label
                  >
                  <span
                    class="badge badge-primary ml-3 p-1"
                    style="cursor: pointer"
                    @click="formatCode"
                    v-if="form.content"
                    >Format Code</span
                  >
                </div>
                <ace-editor
                  v-model="form.content"
                  @init="editorInit"
                  lang="html"
                  theme="chrome"
                  width="100%"
                  height="1000"
                  class="mb-3"
                ></ace-editor>
              </div>
              <div class="col-12 mb-3" v-if="form.content">
                <b-button class="btn btn-info btn-sm" @click="showPreview">
                  <span v-if="previewEmail">Close Preview</span>
                  <span v-else>Preview Email HTML</span>
                </b-button>
                <div v-if="previewEmail && form.content">
                  <label class="my-3 d-block text-center"
                    >Preview of HTML Content</label
                  >
                  <div
                    v-html="form.content"
                    class="d-flex align-items-center"
                  ></div>
                </div>
              </div>
              <div class="col-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                  ><span v-if="this.$route.name == 'add-email-content'"
                    >Save Data</span
                  >
                  <span v-else>Update Data</span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import EmailContent from "../../../mixins/ModuleJs/email-content";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Layout,
    PageHeader,
  },
  mixins: [MixinRequest, EmailContent],
  data() {
    return {
      submitted: false,
      title1: "Add Email Content",
      title2: "Edit Email Content",
      items: [
        {
          text: "Back",
          href: "/email-content",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      email_type: { required },
      subject: { required },
    },
  },
};
</script>
